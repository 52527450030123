.bold-and-underline {
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.underline {}

.spaced-right {
  margin-right: 20px;
}

.spaced-left {
  margin-left: 20px;
}

.text-left {
  margin-left: 30px;
}

.two-column {
  gap: 0 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 3fr;
}

.two-column-v2 {
  display: grid;
  grid-template-columns: 200px auto;
}

.lower-roman {
  counter-reset: item;
  list-style-type: none;
}

.lower-roman li {
  display: flex;
  counter-increment: item;
}

li::marker {
  font-size: 0.625rem !important;
}

.lower-roman li:before {
  font-size: 0.625rem;
  content: ' (' counter(item, lower-roman) ') ';
}

.cv-section {
  line-height: 1.6;
  margin-top: 20px;
}

.cv-section p {
  font-size: 0.625rem;
}

.cv-section-inner {
  padding-left: 10px;
  margin-top: 10px;
  display: grid;
}

.three-column {
  gap: 0 0.25rem;
  width: 100%;
  display: grid;
  grid-template-columns: 80px 1fr 80px;
}

.publication-list .two-column {
  grid-template-columns: 1fr 100px;
}

.one-asterisk::marker {
  font-size: 0.625rem !important;
  content: '* ' counter(list-item) '. ';
}

.two-asterisk::marker {
  font-size: 0.625rem !important;
  content: '** ' counter(list-item) '. ';
}

.loader {
  border: 3px solid hsla(185, 100%, 62%, 0.2);
  border-top-color: #267d77;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  animation: spin 1s linear infinite;
}

.color {
  color: rgba(128, 128, 128, 0.529);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.break-avoid {
  page-break-inside: avoid;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.calc-cell {
  font-size: 12px !important;
  line-height: 1.2 !important;
  padding: 12px !important;
  text-align: center !important;
  color: black !important;
}

.calc-cell-header {
  font-size: 10px !important;
  line-height: 1.2 !important;
  text-align: center !important;
  color: black !important;
}